/* ---------- GLOBAL CSS ------------------------------------------------------------*/
html, body {
  height: 100% !important;;
  background: #2e5a75;
  background: linear-gradient(156deg, rgba(2,0,36,1) 0%, rgba(68,137,159,1) 53%, rgba(238,187,77,1) 100%) fixed !important;
  line-height: 1.5 !important;;
  font-size: calc(15.13514px + .27027vw) !important;;
}
.mh {
  min-height: 330px;
}
.h100 {
  min-height: 100vh !important;
}
.no-margin {
  margin: 0cm !important;
}
.pad {
  padding: 2rem !important;
}
.ltxt {
  text-align: left !important;
}
.toned {
  text-shadow: 0.1rem 0.1rem #353a41 !important;
}
.tonedb {
  text-shadow: 0.1rem 0.1rem #FFFFFF !important;
}
/* ---- Background types ---- */
.bg-lighter {
  background-color: #F7F9FB !important;
}
.no-bg {
  background-color: transparent !important;
}
/* ---- Form focus & checked states ---- */
input:focus, input:checked, .form-control:focus {
  border-color: #28a745 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important;
}
/* ---- Button general styles & animation ---- */
button:active {
  transform: translateY(4px);
  outline: none !important; 
}
button:focus, button:hover {
  transition: 0.5s;
  box-shadow: 8px 8px 0 orange !important;
  outline: none !important; 
  border-color: transparent !important;
}
button {
  box-shadow: 4px 4px 0 #353a41;
}
/* ---- SVG styles & animation ---- */
svg {
  margin-top: -4px;
  margin-right: 10px;
  font-size: large;
}
.happy {
  font-size: 6em;
  color: orange;
  animation: bounce 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes bounce {
  from {transform: rotate(20deg);}
  to {transform: rotate(-20deg);}
}
/* ---- Alert box style ---- */
.errors {
  margin-top: 30px !important;
  color: #FFFFFF !important;
  border-color: #9f4450 !important;
  background-color: #9f4450 !important;
}
.errors svg {
  font-size: 2em !important;
}
/* ---- Navbar & footer style ---- */
.nav-link {
  color: #FFFFFF !important;
}
.nav-link:focus, .nav-link:hover {
  color: #afd7e4 !important;
}
.navbar-dark {
  background-color: rgba(255,255,255,0.20) !important;
  border-color: transparent !important;
}
.footer {
  padding: 0.7rem !important;
  font-size: 0.7em !important;
  background-color: #D3EEF7 !important;
}
/* ---- Pulse animation for attention ---- */
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);}
  100% {box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);}
}
.pulsetxt {
  overflow: hidden;
  background: linear-gradient(90deg, #ffb347, #fff, #ffb347);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: shine 10s linear infinite;
  background-clip: content-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
@keyframes shine {
  0% {background-position: -500%;}
  100% {background-position: 500%;}
}
/* ---------- MAIN COMPONENT CSS ------------------------------------------------------------*/
.loginbox {
  align-items: center !important;
  background-color: #D3EEF7 !important;
  padding: 30px !important;
  padding-top: 2px !important;
  margin-bottom: 1rem !important;
}
.logbox {
 border: none !important;
 display: block !important;
}
.formlabels {
  float: left !important;
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}
.credentials-btn {
  background-color: #449f5b !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
  border: none !important;
  margin: 15px 0 !important;
  padding: 12px 15px !important;
}
small {
  font-size: smaller !important;
}
small a {
  color: #449f88 !important;
}
small a:hover {
  color: #449f88 !important;
  text-decoration: underline !important;
}

/* ---------- QUESTIONS COMPONENT CSS ------------------------------------------------------------*/
.safety-box {
  align-items: center;
  background-color: #d6bc74 !important;
}
.safety-step {
  text-align: left !important;
  padding-left: 20px !important;
  padding-top: 12px !important;
  margin-bottom: 6px !important;
  background-color: aliceblue !important;
  border: 2px solid #9f4450 !important;
  border-radius: 45px !important;
}
.safety-btn {
  background-color: #ad343e !important;
  color: rgb(255, 255, 255) !important;
  font-weight: bold !important;
  border: none !important;
  margin: 20px !important;
  padding: 15px 15px !important;
}
.safety-title {
  font-size: 40px !important;
  font-weight: 800 !important;
  color: #ad343e !important;
  word-wrap: break-word;  
}
/* ---- Instruction type ---- */
.instruction-title {
  font-size: 40px !important;
  font-weight: 800 !important;
  color: #449f5b !important;
  word-wrap: break-word;  
}
.instruction-txt {
  font-style: italic !important;
  color: mediumslateblue !important;
  font-weight: bold !important;
}
/* ---- Note type ---- */
.note-title {
  font-size: 38px !important;
  font-weight: 800 !important;
  color: #44899f !important;
  word-wrap: break-word;  
}
.note-step {
  text-align: left !important;
  padding-left: 20px !important;
  padding-top: 12px !important;
  margin-bottom: 6px !important;
  background-color: aliceblue !important;
  border: 2px solid #117a8b !important;
  border-radius: 45px !important;
}
.note-txt {
  font-weight: bold !important;
  white-space: pre-line !important;
  text-align: left !important;
}
.note-btn {
  background-color: #449f5b !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
  border: none !important;
  margin: 20px 0 !important;
  padding: 15px 15px !important;
}
/* ---- Question type ---- */
.qbox {
  align-items: center !important;
  background-color: #D3EEF7 !important;
  box-shadow: 7px 7px 10px #323332;
}
.qcard {
  background-color: #2e5a75 !important;
  color: white !important;
  padding: 35px !important;
  word-wrap: break-word;  
}
.question-txt {
  font-size: 25px !important;
  font-weight: bold !important;
  text-align: center !important;
}
.q-btn {
  background-color: #EEBB4D !important;
  color: rgb(0, 0, 0) !important;
  font-weight: bold !important;
  border: none !important;
  margin: 20px !important;
  padding: 15px 15px !important;
}
.text-inpt {
  width: 50% !important;
  margin-left: 25% !important;
  text-align: center !important;
  margin-top: 30px !important;
}
.preview {
  max-width: 200px;
}
.table-bordered>:not(caption)>* {
  vertical-align: middle;
}
/* ---- Accordion type ---- */
.sub {
  background-color: #E89781 !important;
}
.accordion>.card-header {
  padding: 1.4rem 1rem !important;
}
.submit-btn {
  background-color: #449f5b !important;
  color: rgb(255, 255, 255) !important;
  font-weight: bold !important;
  border: none !important;
  margin: 20px 0px !important;
  padding: 15px 15px !important;
}
/* ---------- INFO COMPONENT CSS ------------------------------------------------------------------*/
.tbox {
  align-items: center;
  color: #FFFFFF;
}
.general-btn {
  background-color: #345DDA !important;
  color: rgb(255, 255, 255) !important;
  font-weight: bold !important;
  border: none !important;
  margin: 20px 0 !important;
  padding: 15px 15px !important;
}
.info-v {
  background-color: #2e5a75;
  list-style-type: none;
  margin: 0 0 30px;
  padding: 10px;
  border: 6px solid #345DDA;
  box-shadow: 6px 6px 0 #323332;
}
.info-v__step {
  border-bottom: 4px solid #34DAB0;
  margin-bottom: 20px;
  position: relative;
}
.info-v__step::after, .wizard-v__step::before {
  border: medium solid transparent;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  width: 0;
  border-top-color: #34DAB0;
  border-width: 18px;
  left: 50%;
  margin-left: -18px;
}
/* ---------- DASHBOARD COMPONENT CSS -----------------------------------------------------------*/
.bg-complete {
  background-color: #449f5b !important;
  color: white !important;
}
.bg-blue {
  background-color: #2e5a75 !important;
  color: white !important;
}
.bg-yellow {
  background-color: #D8B863 !important;
  color: white !important;
}
.bg-darkgreen {
  background-color: #36897F!important;
  color: white !important;
}
.bg-lightblue {
  background-color: #34B0DA !important;
  color: white !important;
}
.td-lbl {
  font-style: italic;
  font-weight: 500;
}
/* ---------------------- END ------------------------------------------------------------------*/